import pino from 'pino';

const PinoInstance = pino({
    level: 'debug',
    formatters: {
        level: label => {
            return { level: label };
        },
    },
});

export const console: any = ((global: any) => {
    return {
        trace: (...input) => {
            PinoInstance.trace(input);
        },
        fatal: (...input) => {
            PinoInstance.fatal(input);
        },
        log: (...input) => {
            PinoInstance.debug(input);
        },
        info: (...input) => {
            PinoInstance.info(input);
        },
        warn: (...input) => {
            PinoInstance.warn(input);
        },
        error: (...input) => {
            PinoInstance.error(input);
        }
    };
})(global.console);

global.console = console;
