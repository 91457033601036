
import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import axiosInstance from '../../../apis/axiosInstance';
import {
    fetchedTenantSuccess, fetchingTenant, fetchedTenantFailed,
    updateFreshworksAccountAction, updateFreshworksAccountActionSuccess,
    syncFreshworksUsersAction
} from './tenant.actions';
import { API_URLS } from '../../../utils/constants';
import { console } from '../../../utils/logger';
export interface requestOptions {
    method: string;
    headers: HeadersInit;
    redirect: RequestRedirect;
}
function* TenantList(action: PayloadAction<{}>) {
    try {
        const appResponse = yield axiosInstance.get(API_URLS.getTenantAppsUrl);
        const freshworksAccountsResp = yield axiosInstance.get(API_URLS.getFreshworksAccountsUrl);
        const freshworksAccounts = freshworksAccountsResp.data;
        const tenantAccountMap = {};
        for (const account of freshworksAccounts) {
            tenantAccountMap[account.tenantId] = account.accountId;
        }
        const tenantsWithAccountId = appResponse.data.map(tenant => {
            tenant.accountID = tenantAccountMap[tenant.tenantID];
            return tenant;
        });
        if (appResponse.status === 200) {
            yield put(fetchedTenantSuccess(tenantsWithAccountId))
        }
    }
    catch (error) {
        yield put(fetchedTenantFailed())
    }
}

function* updateFreshworksAccount(action) {
    const { tenantDetails, tenantList } = action.payload;
    try {
        yield axiosInstance.post(API_URLS.postFreshworksAccountsUrl, tenantDetails);
        const newTenantList = tenantList.map(tenant => {
            let newTenant = tenant;
            if (tenant.tenantID === tenantDetails.tenantID) {
                newTenant = JSON.parse(JSON.stringify(tenant));
                newTenant.accountID = tenantDetails.accountID;
            }
            return newTenant;
        });
        yield put(updateFreshworksAccountActionSuccess(newTenantList));
    } catch (error) {
        alert('Error! Check logs')
        console.error(error);
    }
}

function* syncFreshworksUsers(action) {
    const { tenantId } = action.payload;
    try {
        yield axiosInstance.post(API_URLS.postSyncFreshworksUsersUrl, { tenant_id: tenantId });
        alert(`Sync successful for tenant id: ${tenantId}`);
    }
    catch (error) {
        alert('Error! Check logs');
        console.error(error);
    }
}

export const tenantEffects = [
    takeEvery([fetchingTenant.type], TenantList),
    takeLatest(updateFreshworksAccountAction, updateFreshworksAccount),
    takeLatest(syncFreshworksUsersAction, syncFreshworksUsers)
];
