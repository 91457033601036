import { createReducer } from '@reduxjs/toolkit';
import { fetchedTenantFailed, fetchedTenantSuccess, fetchingTenant, SEARCH, updateFreshworksAccountActionSuccess } from './tenant.actions';

export interface Tenant {

  loading: boolean;
  tenantList: Array<any>;

}

const initialState: Tenant = {
  loading: false,
  tenantList: []
}

export const tenantReducer = createReducer(initialState,builder => {
  builder.addCase(fetchingTenant, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  });

  builder.addCase(fetchedTenantSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      tenantList: action.payload
    };
  });
  builder.addCase(SEARCH, (state, action) => {
    return {
      ...state,
      tenantList: state.tenantList.filter(
        (tenant:any) => tenant.tenantName.includes(action.payload)
      ),
    }
  })
  builder.addCase(fetchedTenantFailed, (state, _) => {
    return {
      ...state,
      loading: false
    };
  });
  builder.addCase(updateFreshworksAccountActionSuccess, (state, action) => {
    return {
      ...state,
      tenantList: action.payload
    }
  })
});
